import { Link } from 'gatsby'
import React, { Component } from 'react'

import FooterLogo from '../assets/images/logo-footer.svg'
import Workplace from '../assets/images/best-workplaces.png'
import Endorsed from '../assets/images/endorsed.png'
import LogoDark from '../assets/images/logodark.png'
import { LinkedIn } from '../components/icons'

class Footer extends Component {
  render() {
    let { settings, footer_one, footer_sectors } = this.props
    return (
      <footer className='footer'>
        <div className='footer__inner container'>
          <div className="footer__col footer__col--logo">
            <div className='footer__logo'>
              <Link to='/' title='FSC Group'>
                <img className='img' src={FooterLogo} alt="FSC Group" />
                <img className="footer_alternate img" src={LogoDark} alt="FSC Group"/>
              </Link>
              <Link to='https://greatplacetowork.com.au/australia-certified-companies/'>
                <img className='badge' src={Workplace} alt="Best Workplaces" />
              </Link>
              <Link to='https://work180.com/en-au/for-women/employer/fsc-group-pty'>
                <img className='badge' src={Endorsed} alt="Endorsed by WORK180" />
              </Link>
            </div>
            <p className="footer__copy desktop">2023 © Copyright FSC Group Pty Ltd. All rights reserved. | <a href="https://atollon.com.au">Design Atollon</a></p>
          </div>
          <div className="footer__col footer__col--line">
            <p>1300 372 477<br />
            Level 1, 366A Bridge Rd, Richmond VIC 3121<br />
            123 Eagle Street, Brisbane QLD 4000<br />
            20/40 Meagher Street, Chippendale NSW 2008<br />
            
            <Link to="/contact">Email Contact</Link></p>
          </div>
          <div className="footer__col footer__col--line">
            <ul>
              { footer_one.edges[0].node.items.map((el, i) => {
                  return (
                    <li key={i}>
                      <Link to={el.url}>{el.title}</Link>
                    </li>
                  )
                }) }
            </ul>
          </div>
          <div className="footer__col">
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
              <li className="footer__social desktop"><a href={settings.linkedin}><LinkedIn color="#fff" /></a></li>
            </ul>
          </div>
          <div className="footer__col">
            <ul>
              { footer_sectors.edges[0].node.items.map((el, i) => {
                  return (
                    <li key={i}>
                      <Link to={el.url}>{el.title}</Link>
                    </li>
                  )
                }) }
              <li className="footer__social mobile"><a href={settings.linkedin}><LinkedIn color="#fff" /></a></li>
            </ul>
          </div>
          <p className="footer__copy mobile">2023 © Copyright FSC Group Pty Ltd. All rights reserved. | <a href="https://atollon.com.au">Design</a> + <a href="https://www.codedrips.com">Development</a></p>
        </div>
      </footer>
    )
  }
}

Footer.defaultProps = {
  siteTitle: ``,
  settings: {},
  footer_one: {},
  footer_sectors: {},
}

export default Footer
