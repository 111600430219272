import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="160px" height="58px" viewBox="0 0 160 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="#logo" transform="translate(-140.000000, -40.000000)" fill={color}>
            <g transform="translate(140.000000, 40.000000)">
              <path d="M0,0v58h139.6V0H0z M37.6,17.5H22.2v7.6h10.6v8.7H22.2v14.5h-9.3V8.8h24.7V17.5z M62.9,49.1c-8.5,0-14-3.9-16.3-9.8l8-4.5
	c1.5,3.3,4,5.5,8.5,5.5c4.4,0,5.5-1.7,5.5-3.2c0-2.4-2.3-3.4-8.4-5c-6-1.6-12-4.5-12-12C48.3,12.4,55,8,62,8
	c6.7,0,11.9,3.1,14.9,8.9L69,21.4c-1.4-2.8-3.3-4.6-7-4.6c-2.9,0-4.4,1.4-4.4,3c0,1.8,1,3,7.3,4.9C71.1,26.5,78,28.6,78,36.9
	C78,44.5,71.8,49.1,62.9,49.1z M108.6,49.1c-11.7,0-21.2-9.2-21.2-20.5C87.5,17.2,96.9,8,108.6,8c7.6,0,14.3,3.9,18,9.8l-8,4.2
	c-2.2-3.1-5.8-5.2-10-5.2c-6.7,0-12.1,5.2-12.1,11.7s5.4,11.7,12.1,11.7c4.2,0,7.9-2.1,10.1-5.3h0.1l7.9,4.2
	C123,45.1,116.3,49.1,108.6,49.1z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

Logo.defaultProps = {
  color: `#ffffff`,
}

export default Logo
