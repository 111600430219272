import { Link } from 'gatsby'
import React, { Component } from 'react'
import onClickOutside from "react-onclickoutside"

import Logo from '../components/logo'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    dropdownOpen: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleDropdown = (dropdownItem) => {
    this.setState({dropdownOpen: {[dropdownItem]: !this.state.dropdownOpen[dropdownItem]} })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  handleClickOutside = evt => {
    this.setState({ offCanvas: false })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    let nextState = currentScroll > 0
    if (nextState !== scrolled) this.setState({ scrolled: nextState })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let headerClass = `header`
    let color = '#003052'

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let { main } = this.props

    if (offCanvas) headerClass += ' header--inverse'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner container'>
            <Link to='/' title='FSC Group' className='header__logo' {...props}>
          <Logo color='#000000' />
            </Link>
            <div className="header__right">
              <Link className="header__get-in" to="/contact/" {...props}>General Enquiry</Link>
              <Link className="header__get-in" to="/client-enquiry/" {...props}>Client Enquiry</Link>
              <button id="burger" onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'active' : '' }>
                <span className="text">Explore</span>
                <span className="lines"></span>
              </button>
            </div>
          </div>
          <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`} ref='menu'>
            <button id="burger" onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'active' : '' }>
              <span className="text">Close</span>
              <span className="lines"></span>
            </button>
            <nav>
              <ul>
                { main.edges && main.edges[0].node.items.map((el, i) => {
                  return (
                    <li key={i} className={`${el.wordpress_children && el.wordpress_children.length > 0 ? 'has-sub' : ''} ${this.state.dropdownOpen[i] ? 'active' : ''}`}>
                      { el.wordpress_children && el.wordpress_children.length > 0 && 
                        <span onClick={() => this._toggleDropdown(i)}><span>{el.title}</span></span>
                      }
                      { !el.wordpress_children && 
                        <Link to={el.url} {...props}><span>{el.title}</span></Link>
                      }
                      { el.wordpress_children && el.wordpress_children.length > 0 &&
                        <ul className='sub'>
                          { el.wordpress_children.map((child, x) => {
                            return (
                              <li key={x}>
                                <Link to={child.url} {...props}><span>{child.title}</span></Link>
                              </li>
                            )
                          }) }
                        </ul>
                      }
                    </li>
                  )
                }) }
              </ul>
            </nav>
          </div>
        </header>
      </>
    )
  }
}

Header.defaultProps = {
  siteTitle: ``,
  main: {},
}

export default onClickOutside(Header)
